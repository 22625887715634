import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { logoutUser } from "./Redux/Store";
const Header = () => {
  const user = useSelector((state) => state.user);
  const navigate = useLocation();
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(logoutUser());
  };

  return (
    <nav className="main-header navbar navbar-expand navbar-info navbar-light">
      <ul className="navbar-nav">
        <li className="nav-item">
          <a
            className="nav-link text-white"
            data-widget="pushmenu"
            href="#"
            role="button"
          >
            <i className="fas fa-bars"></i>
          </a>
        </li>
      </ul>
      <ul className="navbar-nav ml-auto">
        <li className="nav-item dropdown user-menu">
          <a
            href="#"
            className="nav-link dropdown-toggle text-white"
            data-toggle="dropdown"
          >
            {user.firstname}
          </a>
          <ul
            className="dropdown-menu dropdown-menu-sm dropdown-menu-right"
            style={{ width: "180px" }}
          >
            <li className="nav-item text-center">
              <a href="profile-settings.html" className="nav-link">
                My Profile
              </a>
            </li>
            <li className="nav-item text-center admin-logout">
              <Link to="" className="nav-link" onClick={logout}>
                Logout
              </Link>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  );
};

export default Header;
