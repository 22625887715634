import React, { useState, useEffect } from "react";
import axiosInstance from "../axiosConfig";
import Swal from "sweetalert2";
import $ from "jquery";
import "datatables.net-dt";
import "datatables.net-responsive-dt/css/responsive.dataTables.css";
import "datatables.net-responsive";
import "datatables.net-dt/css/dataTables.dataTables.min.css";

const ServiceType = () => {
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({ servicetype: "" });
  const [errors, setErrors] = useState({});
  const [customers, setCustomers] = useState([]);

  const fetchCustomers = async () => {
    try {
      const response = await axiosInstance.get("/servicetype");
      $("#load").hide();

      if (response?.status === "success") {
        setCustomers(response.data);
      } else {
        console.error("Error fetching customers:", response);
        setCustomers([]);
      }
    } catch (error) {
      setCustomers([]);
      console.error("Error fetching customers:", error);
    }
  };

  const handleModalOpen = (customer) => {
    setShowModal(true);
    if (customer) {
      setFormData(customer);
    } else {
      setFormData({ servicetype: "" });
    }
    setErrors({});
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.servicetype)
      newErrors.servicetype = "Service type is required.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const data = new FormData();
    data.append("servicetype", formData.servicetype);
    if (formData.id) data.append("active", formData.active);

    try {
      const response = formData.id
        ? await axiosInstance.put(`/servicetype?id=${formData.id}`, data)
        : await axiosInstance.post("/servicetype", data);

      if (response?.status === "success") {
        fetchCustomers();
        Swal.fire({
          toast: true,
          position: "top-end",
          icon: "success",
          title: "Service Type " + response.message,
          showConfirmButton: false,
          timer: 1500,
        });
        handleModalClose();
      } else {
        console.error("Error in response:", response);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await axiosInstance.delete(`/servicetype?id=${id}`);
      if (response?.status === "success") {
        fetchCustomers();
        Swal.fire({
          toast: true,
          position: "top-end",
          icon: "success",
          title: "Service Type " + response.message,
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        console.error("Error deleting servicetype:", response);
      }
    } catch (error) {
      console.error("Error deleting servicetype:", error);
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, []);

  useEffect(() => {
    if ($.fn.DataTable.isDataTable("#type-list")) {
      $("#type-list").DataTable().clear().destroy();
    }
    // if (customers.length > 0) {
    $("#type-list").DataTable({
      responsive: true,
      data: customers,
      columns: [
        { data: null, render: (data, type, row, meta) => meta.row + 1 }, // S NO.
        { data: "servicetype" },
        {
          data: "active",
          render: (data) => (data === 1 ? "Active" : "Inactive"),
        },
        {
          data: null,
          render: (data) => `
              <button class="btn btn-dark btn-sm edit-btn">Edit</button>
              <button class="btn btn-danger btn-sm delete-btn">Delete</button>
            `,
        },
      ],
      language: {
        lengthMenu: "Show _MENU_ entries",
        search: "Search:",
        paginate: {
          next: "Next",
          previous: "Previous",
        },
        info: "Showing _START_ to _END_ of _TOTAL_ entries",
      },
    });

    // Event listeners for edit and delete buttons
    $("#type-list tbody").on("click", ".edit-btn", function () {
      const rowData = $("#type-list")
        .DataTable()
        .row($(this).parents("tr"))
        .data();
      handleModalOpen(rowData);
    });

    $("#type-list tbody").on("click", ".delete-btn", function () {
      const rowData = $("#type-list")
        .DataTable()
        .row($(this).parents("tr"))
        .data();
      handleDelete(rowData.id);
    });
    // }
  }, [customers]);

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              <h1 className="m-0 text-dark">Service Types</h1>
            </div>
            <div className="col-sm-6 text-right">
              <button
                className="btn btn-info btn-sm"
                onClick={() => handleModalOpen(null)}
              >
                + Add New
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="card mx-3 shadow">
        <div className="card-body table-responsive">
          <table id="type-list" className="table table-bordered table-striped">
            <thead>
              <tr>
                <th>S NO.</th>
                <th>Service Type</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
      </div>

      {showModal && (
        <div className="modal fade show" style={{ display: "block" }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">
                  {formData.id ? "Edit Service Type" : "Add Service Type"}
                </h4>
                <button className="close" onClick={handleModalClose}>
                  <span>&times;</span>
                </button>
              </div>

              <form onSubmit={handleSubmit}>
                <div className="modal-body">
                  <div className="form-group">
                    <label>
                      Service Type Name<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="servicetype"
                      className="form-control"
                      value={formData.servicetype}
                      onChange={handleChange}
                    />
                    {errors.servicetype && (
                      <small className="text-danger">
                        {errors.servicetype}
                      </small>
                    )}
                  </div>

                  {formData.id && (
                    <div className="form-group">
                      <label>Status</label>
                      <select
                        name="active"
                        className="form-control"
                        value={formData.active}
                        onChange={handleChange}
                      >
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                      </select>
                    </div>
                  )}
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-info">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ServiceType;
