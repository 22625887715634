import React, { useEffect, useState } from "react";
import "./Login.css"; // Custom styles for the login page
import axiosInstance from "../axiosConfig";
import { useDispatch } from "react-redux";
import { setUser } from "../Redux/Store"; // Import the setUser action
import { useNavigate } from "react-router-dom";
import $ from "jquery";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loginuser = async (e) => {
    if (username != "" && password != "") {
      try {
        const response = await axiosInstance.get("/user", {
          params: {
            Email: username,
            Password: password,
          },
        });
        if (response?.status == "success" && response?.data.length > 0) {
          dispatch(setUser(response?.data[0]));
          navigate("/dashboard");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  useEffect(() => {
    $("#load").hide();
  }, []);
  return (
    <div className="login-page">
      <div className="login-container">
        <h2 className="text-center">Login</h2>
        <form id="loginForm" method="POST" action="/login">
          <div className="form-group">
            <label htmlFor="username">Username</label>
            <input
              type="text"
              className="form-control"
              id="username"
              name="username"
              value={username}
              onChange={(e) => {
                setUsername(e.target.value);
              }}
              required
              placeholder="Enter your username"
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              className="form-control"
              id="password"
              name="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              required
              placeholder="Enter your password"
            />
          </div>
          <button
            type="button"
            className="btn btn-info btn-block"
            onClick={loginuser}
          >
            Login
          </button>
        </form>
        <div className="text-center mt-3">
          <a href="/forgot-password">Forgot Password?</a>
        </div>
      </div>
    </div>
  );
};

export default Login;
