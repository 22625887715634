import React, { useState, useEffect } from "react";
import axiosInstance from "../axiosConfig";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import Swal from "sweetalert2";
import "datatables.net-dt";
import "datatables.net-responsive-dt/css/responsive.dataTables.css";
import "datatables.net-responsive";
import "datatables.net-dt/css/dataTables.dataTables.min.css";

const Service = () => {
  const [customers, setCustomers] = useState([]);
  const navigate = useNavigate();
  const [serviceTypes, setServiceTypes] = useState([]);

  const fetchCustomers = async () => {
    try {
      const response = await axiosInstance.get("/service");
      $("#load").hide();
      if (response.status === "success") {
        // Check for success status code
        setCustomers(response.data);

        const response2 = await axiosInstance.get(
          "/serviceprice?columns=id,service,serviceid,price"
        );
        if (response2.status === "success") {
          // Check for success status code
          setServiceTypes(response2.data);
        }
      } else {
        console.error("Error fetching customers:", response);
        setCustomers([]);
      }
    } catch (error) {
      setCustomers([]);
      console.error("Error fetching customers:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await axiosInstance.delete(`/service?id=${id}`);
      if (response.status === "success") {
        // Check for success status code
        Swal.fire({
          toast: true,
          position: "top-end",
          icon: "success",
          title: "Service Type " + response.message,
          showConfirmButton: false,
          timer: 1500,
        });
        fetchCustomers(); // Fetch updated customers after deletion
      } else {
        console.error("Error deleting service type:", response);
      }
    } catch (error) {
      console.error("Error deleting service type:", error);
    }
  };

  const handleModalOpen = (customer) => {
    navigate("/createservice", {
      state: { data: customer === null ? null : customer.id },
    });
  };

  useEffect(() => {
    fetchCustomers();
  }, []); // Fetch customers on mount

  useEffect(() => {
    if ($.fn.DataTable.isDataTable("#service-list")) {
      $("#service-list").DataTable().clear().destroy();
    }

    $("#service-list").DataTable({
      responsive: true,
      data: customers,
      columns: [
        { data: null, render: (data, type, row, meta) => meta.row + 1 }, // S NO.
        {
          data: "serviceImg",
          render: (data) => `
              <img
                src="../../images/${data}"
                alt=""
                width="80px"
                height="80px"
              />
            `,
        },
        { data: "serviceName" },
        {
          data: "id",
          render: (data) => {
            const services = serviceTypes
              .filter((item) => data === item.serviceid)
              .map((item) => item.service)
              .join(", ");

            return services;
          },
        },
        {
          data: "active",
          render: (data) => (data === 1 ? "Active" : "Inactive"),
        },
        {
          data: null,
          render: (data) => `
              <button class="btn btn-dark btn-sm edit-btn">Edit</button>
              <button class="btn btn-danger btn-sm delete-btn">Delete</button>
            `,
        },
      ],
      language: {
        lengthMenu: "Show _MENU_ entries",
        search: "Search:",
        paginate: {
          next: "Next",
          previous: "Previous",
        },
        info: "Showing _START_ to _END_ of _TOTAL_ entries",
      },
    });

    $("#service-list tbody").on("click", ".edit-btn", function () {
      const rowData = $("#service-list")
        .DataTable()
        .row($(this).parents("tr"))
        .data();
      handleModalOpen(rowData);
    });

    $("#service-list tbody").on("click", ".delete-btn", function () {
      const rowData = $("#service-list")
        .DataTable()
        .row($(this).parents("tr"))
        .data();
      handleDelete(rowData.id);
    });
  }, [serviceTypes, customers]);

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              <h1 className="m-0 mr-2 text-dark d-inline-block">Services</h1>
            </div>
            <div className="col-sm-6 text-right align-self-center">
              <button
                type="button"
                data-toggle="modal"
                data-target="#modal-default"
                className="align-top btn btn-sm btn-info d-inline-block"
                onClick={() => handleModalOpen(null)}
              >
                + Add New
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="card mx-3 shadow">
        <div className="card-body table-responsive">
          <table
            id="service-list"
            className="table table-bordered table-striped"
          >
            <thead>
              <tr>
                <th>S NO.</th>
                <th>Service Image</th>
                <th>Service Name</th>
                <th>Service Types</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Service;
