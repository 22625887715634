import React from "react";
import { useLocation } from "react-router-dom"; // Import useLocation

const SideMenu = () => {
  const location = useLocation(); // Get the current location

  // Function to check if the current path matches the nav item's path
  const isActive = (path) => {
    return location.pathname === path ? "active" : "";
  };

  return (
    <aside className="main-sidebar sidebar-light-info elevation-4">
      <a href="#" className="brand-link text-center bg-white text-dark">
        <img
          src="../site-img/1261601831laundry.png"
          alt="Express Laundry"
          style={{ width: "90%" }}
        />
      </a>
      <div className="sidebar pt-3">
        <nav className="mt-2">
          <ul
            className="nav nav-pills nav-sidebar flex-column font-weight-bold"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            <li className="nav-item">
              <a
                href="/dashboard"
                className={`nav-link ${isActive("/dashboard")}`}
              >
                <i className="nav-icon fas fa-home"></i>
                <p> Dashboard </p>
              </a>
            </li>
            <li className="nav-item">
              <a
                href="/createorder"
                className={`nav-link ${isActive("/createorder")}`}
              >
                <i className="nav-icon fas fa-tag"></i>
                <p>Create Order</p>
              </a>
            </li>
            <li className="nav-item">
              <a
                href="/orderlist"
                className={`nav-link ${isActive("/orderlist")} ${isActive(
                  "/orderview"
                )}`}
              >
                <i className="nav-icon fab fa-first-order"></i>
                <p>Orders</p>
              </a>
            </li>
            <li className="nav-item">
              <a
                href="/order-screen"
                className={`nav-link ${isActive("/order-screen")}`}
              >
                <i className="nav-icon fas fa-list"></i>
                <p>Orders Status Screen</p>
              </a>
            </li>
            <li className="nav-item">
              <a
                href="/customer"
                className={`nav-link ${isActive("/customer")}`}
              >
                <i className="nav-icon fas fa-user"></i>
                <p>Customers</p>
              </a>
            </li>
            <li className="nav-item">
              <a href="/ironman" className={`nav-link ${isActive("/ironman")}`}>
                <i className="nav-icon fas fa-user"></i>
                <p>Iron Man</p>
              </a>
            </li>
            <li className="nav-item has-treeview">
              <a href="javascript:void(0)" className="nav-link">
                <i className="nav-icon fas fa-bullhorn"></i>
                <p>
                  {" "}
                  Services <i className="fas fa-angle-left right"></i>
                </p>
              </a>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <a
                    href="/services"
                    className={`nav-link ${isActive("/services")}`}
                  >
                    <i className="nav-icon fa fa-solid fa-tag"></i>
                    <p>All Services</p>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="/servicetype"
                    className={`nav-link ${isActive("/servicetype")}`}
                  >
                    <i className="nav-icon fa fa-list"></i>
                    <p>Service Types</p>
                  </a>
                </li>
                {/* <li className="nav-item">
                  <a
                    href="/addons"
                    className={`nav-link ${isActive("/addons")}`}
                  >
                    <i className="nav-icon fa fa-puzzle-piece"></i>
                    <p>Addons</p>
                  </a>
                </li> */}
              </ul>
            </li>
            <li className="nav-item has-treeview">
              <a href="javascript:void(0)" className="nav-link">
                <i className="nav-icon fas fa-signal"></i>
                <p>
                  {" "}
                  Reports <i className="fas fa-angle-left right"></i>
                </p>
              </a>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <a
                    href="/order-report"
                    className={`nav-link ${isActive("/order-report")}`}
                  >
                    <i className="nav-icon fab fas fa-chart-line"></i>
                    <p>Order Report</p>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="/sales-report"
                    className={`nav-link ${isActive("/sales-report")}`}
                  >
                    <i className="nav-icon fas fa-chart-pie"></i>
                    <p>Sales Report</p>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="/ex-reports"
                    className={`nav-link ${isActive("/ex-reports")}`}
                  >
                    <i className="nav-icon fa fa-copy"></i>
                    <p>Expense Report</p>
                  </a>
                </li>
              </ul>
            </li>
            <li className="nav-item has-treeview">
              <a href="javascript:void(0)" className="nav-link">
                <i className="nav-icon fa fa-wrench"></i>
                <p>
                  {" "}
                  Settings <i className="fas fa-angle-left right"></i>
                </p>
              </a>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <a
                    href="/media-gallery"
                    className={`nav-link ${isActive("/media-gallery")}`}
                  >
                    <i className="nav-icon fas fa-image"></i>
                    <p>Media</p>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="/general-settings"
                    className={`nav-link ${isActive("/general-settings")}`}
                  >
                    <i className="fas fa-cogs nav-icon"></i>
                    <p>General Setting</p>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="/profile-settings"
                    className={`nav-link ${isActive("/profile-settings")}`}
                  >
                    <i className="nav-icon fa fa-user"></i>
                    <p>Profile Setting</p>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default SideMenu;
