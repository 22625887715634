import React, { useState, useEffect, useRef } from "react";
import axiosInstance from "../axiosConfig";
import Swal from "sweetalert2";
import Print from "../Print";
import $, { event } from "jquery";
import "datatables.net-dt";
import "datatables.net-responsive-dt/css/responsive.dataTables.css";
import "datatables.net-responsive";
import "datatables.net-dt/css/dataTables.dataTables.min.css";
import { useNavigate, useLocation } from "react-router-dom";

const Orderview = () => {
  const navigate = useNavigate();
  const tableRef = useRef(null);
  const [printMode, setPrintMode] = useState(false);
  const [selectIron, setSelectIron] = useState(false);
  const [selectedIron, setSelectedIron] = useState({});
  const [ironMan, setIronMan] = useState([]);
  const [order, setOrder] = useState({});
  const [customer, setCustomer] = useState({});
  const [service, setService] = useState([]);
  const [payment, setPayment] = useState([]);
  const [status, setStatus] = useState("");
  const [bal, setBal] = useState(0);

  const location = useLocation();
  let id = location.state.data;

  const getorder = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const searchParams = new URLSearchParams();
        searchParams.append("orderid", id);

        const orderData = await axiosInstance.get(`/orders?${searchParams}`);

        if (orderData.status === "success") {
          setOrder(orderData.data[0]);
          setBal(orderData.data[0].balanceAmt);
          resolve(orderData.data[0]); // Resolve with the order data
        } else {
          reject(new Error("Failed to fetch order.")); // Reject if not successful
        }
      } catch (error) {
        reject(error); // Reject on any error during the request
      }
    });
  };
  const getIronman = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const ironman = await axiosInstance.get(`/ironmans`);

        if (ironman.status === "success") {
          setIronMan(ironman.data);
          resolve(ironman.data);
        } else {
          reject(new Error("Failed to fetch ironman.")); // Reject if not successful
        }
      } catch (error) {
        reject(error); // Reject on any error during the request
      }
    });
  };

  const getcustomer = async (ord) => {
    console.log(ord);

    const cus = await axiosInstance.get(`/customer?id=${ord?.customerid}`);

    if (cus.status == "success") {
      setCustomer(cus.data[0]);
    }
  };

  const getitems = async (ord) => {
    const itemData = await axiosInstance.get(`/orderitems?orderid=${ord.id}`);
    if (itemData.status == "success") {
      setService(itemData.data);
      setOrder((prevState) => ({
        ...prevState,
        services: itemData.data,
      }));
    }
  };

  const getpayment = async (ord) => {
    const paymentData = await axiosInstance.get(
      `/orderpayment?orderid=${ord.id}`
    );
    if (paymentData.status == "success") {
      setPayment(paymentData.data);
      $("#load").hide();
    } else {
      $("#load").hide();
    }
  };

  const addPayment = async (event) => {
    event.preventDefault();

    const pt = $("#paymentType").val();
    if (bal <= 0) {
      Swal.fire({
        toast: true,
        position: "top-end",
        icon: "error",
        title: "Paid Amount should be 0 or greater than 0 ",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        customClass: {
          popup: "custom-toast",
        },
      });
      return;
    }

    if (bal > 0) {
      if (pt === "" || pt === null) {
        Swal.fire({
          toast: true,
          position: "top-end",
          icon: "error",
          title: "Select Payent method",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          customClass: {
            popup: "custom-toast",
          },
        });
        return;
      }
    }

    var orderpayment = {
      orderid: order.id,
      paidAmt: bal,
      paymentType: pt,
    };
    const paymentData = await axiosInstance.post(`/orderpayment`, orderpayment);

    if (paymentData.status == "success") {
      const form = new FormData();

      form.append("balanceAmt", order.balanceAmt - bal);
      form.append("paidAmt", order.paidAmt + bal);

      const orderData = await axiosInstance.put(`/orders?id=${order.id}`, form);
      if (orderData.status == "success") {
        getOrderDetails();

        Swal.fire({
          toast: true,
          position: "top-end",
          icon: "success",
          title: "Order payment " + orderData.message,
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          customClass: {
            popup: "custom-toast",
          },
        });
        $("#close-payment").click();
      } else {
        Swal.fire({
          toast: true,
          position: "top-end",
          icon: "error",
          title: "Order payment " + orderData.message,
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          customClass: {
            popup: "custom-toast",
          },
        });
      }
    } else {
    }
  };

  const getOrderDetails = () => {
    getIronman()
      .then((im) => {
        getorder()
          .then((ord) => {
            console.log(ord, "after");

            if (ord != null) {
              const actualService = im?.find(
                (s) => s.id === parseInt(ord.ironmanid)
              );
              setSelectedIron(actualService);
              getcustomer(ord);
              getitems(ord);
              getpayment(ord);
            } else {
              console.log("Order not found");
            }
          })
          .then(() => {
            console.log(
              "Customer, items, and payment details fetched successfully."
            );
          })
          .catch((error) => {
            console.error("Error fetching order details:", error);
          });
      })
      .catch((error) => {
        console.error("Error fetching ironman details:", error);
      });
  };

  const updateStatus = async () => {
    const form = new FormData();

    form.append("status", status);
    form.append("ironmanid", selectedIron?.id);
    form.append("ironman", selectedIron?.ironmanName);

    const paymentData = await axiosInstance.put(`/orders?id=${order.id}`, form);
    if (paymentData.status == "success") {
      getorder();
      setSelectIron(false);
      Swal.fire({
        toast: true,
        position: "top-end",
        icon: "success",
        title: "Order Status " + paymentData.message,
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        customClass: {
          popup: "custom-toast",
        },
      });
    } else {
      Swal.fire({
        toast: true,
        position: "top-end",
        icon: "error",
        title: "Order Status " + paymentData.message,
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        customClass: {
          popup: "custom-toast",
        },
      });
    }
  };

  const changeStatus = async (evt) => {
    setStatus(evt.target.value);
    debugger;
    if (
      (order?.status === "Pending" || order?.status === "") &&
      evt.target.value === "Processing"
    ) {
      setSelectIron(true);
    } else if (
      order?.status === "Processing" ||
      order?.status === "Ready to Deliver" ||
      order?.status === "Delivered" ||
      order?.status === "Returned"
    ) {
      updateStatus();
    } else {
      Swal.fire({
        toast: true,
        position: "top-end",
        icon: "error",
        title: "Please select Processing after that you change other status",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        customClass: {
          popup: "custom-toast",
        },
      });
    }
  };

  const printReceipt = () => {
    setPrintMode(true);

    setTimeout(() => {
      window.print();
      setPrintMode(false);
    }, 100);
  };

  const changeIron = (evt) => {
    const actualService = ironMan.find(
      (s) => s.id === parseInt(evt.target.value)
    );
    setSelectedIron(actualService);
  };

  useEffect(() => {
    getOrderDetails();
  }, []);

  return !printMode ? (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              <h1 className="m-0 mr-2 text-dark d-inline-block">
                Orders Details
              </h1>
            </div>

            <div className="col-sm-6 text-right align-self-center"></div>
          </div>
        </div>
      </div>

      <section className="content-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-9 col-sm-12">
              <div className="card">
                <div className="card-header">
                  <div className="row">
                    <div className="col-6">
                      <h5 className="text-uppercase font-weight-bold">
                        #{order?.orderid}
                      </h5>
                      <div>
                        <h6 className="">Invoice To:</h6>
                        <p className="m-0 text-capitalize">
                          <b>{customer?.customerName}</b>
                        </p>
                        <p className="m-0 text-capitalize">
                          {customer?.address}
                        </p>
                        <p className="m-0">{customer?.email}</p>
                        <p className="m-0">{customer?.phone}</p>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="align-self-center">
                        <span>Status:</span>
                      </div>
                      <div>
                        <select
                          name="status"
                          className="form-control change-status"
                          value={order?.status}
                          onChange={changeStatus}
                        >
                          <option value="Pending">Pending</option>
                          <option value="Processing">Processing</option>
                          <option value="Ready to Deliver">
                            Ready to Deliver
                          </option>
                          <option value="Delivered">Delivered</option>
                          <option value="Returned">Returned</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-md-4">
                      <p>
                        <span>Order Date:</span>
                        <span className="font-weight-bold ms-2">
                          {new Date(order?.orderdate).toLocaleDateString(
                            "en-US",
                            {
                              year: "numeric",
                              month: "short",
                              day: "2-digit",
                            }
                          )}
                        </span>
                      </p>
                    </div>
                    <div className="col-md-4">
                      <p>
                        <span>Delivery Date:</span>
                        <span className="font-weight-bold ms-2">
                          {new Date(order?.deliveryDate).toLocaleDateString(
                            "en-US",
                            {
                              year: "numeric",
                              month: "short",
                              day: "2-digit",
                            }
                          )}
                        </span>
                      </p>
                    </div>
                    <div className="col-md-4 d-flex flex-row">
                      <div>
                        <h6 className="">
                          Ironing Man:
                          <span className="m-0 text-capitalize">
                            <b> {selectedIron?.ironmanName}</b>
                          </span>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table">
                      <thead className="bg-light">
                        <tr>
                          <th>#</th>
                          <th>Service Name</th>
                          <th>Rate</th>
                          <th>Qty</th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {service &&
                          service?.map((item, index) => {
                            return (
                              <tr>
                                <td>{index + 1}</td>
                                <td>
                                  {item.serviceName}
                                  <p className="text-muted mb-0">
                                    [{item.servicetypeName}]
                                  </p>
                                </td>
                                <td>
                                  <i className="fas fa-rupee-sign"></i>{" "}
                                  {item.price}
                                </td>
                                <td>{item.qty}</td>
                                <td>
                                  <i className="fas fa-rupee-sign"></i>
                                  {item.qty * item.price}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="card-footer">
                  <div className="row">
                    <div className="col-md-8"></div>
                    <div className="col-md-4">
                      <h5 className="font-weight-bold">Payment Details</h5>
                      <div className="row">
                        <div className="col">Sub Total:</div>
                        <div className="col-auto">
                          {" "}
                          <i className="fas fa-rupee-sign"></i> {order.totalAmt}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">Discount:</div>
                        <div className="col-auto">
                          {" "}
                          - <i className="fas fa-rupee-sign"></i>{" "}
                          {order.discount}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">Paid Amount:</div>
                        <div className="col-auto">
                          {" "}
                          - <i className="fas fa-rupee-sign"></i>{" "}
                          {order.paidAmt}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <b>Grand Total:</b>
                        </div>
                        <div className="col-auto">
                          {" "}
                          <i className="fas fa-rupee-sign"></i>{" "}
                          {order.netAmt - order.paidAmt}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-12">
              <div className="card">
                <div className="card-body">
                  <div>
                    <h5 className="font-weight-bold mb-3">Payments</h5>
                    <ul className="list-group">
                      {payment?.map((item) => (
                        <li className="list-group-item">
                          <b>
                            <i className="fas fa-rupee-sign"></i> {item.paidAmt}{" "}
                          </b>
                          <p className="text-muted">
                            {new Date(item?.createdon).toLocaleDateString(
                              "en-US",
                              {
                                year: "numeric",
                                month: "short",
                                day: "2-digit",
                              }
                            )}
                            [{item.paymentType}]
                          </p>
                        </li>
                      ))}
                    </ul>
                    <button className="btn btn-sm btn-block btn-info mb-2 payment-modal">
                      Add Payment
                    </button>
                    <a
                      className="btn btn-sm btn-block btn-dark mb-2"
                      style={{ color: "white" }}
                      onClick={printReceipt}
                    >
                      Print Invoice
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        className="modal fade"
        id="payment-modal"
        style={{ display: "none" }}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-md">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Payment Details</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                id="close-payment"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row border-bottom pb-3 mb-3">
                <div className="col-6">
                  <p className="mb-0">Customer Name:</p>
                  <p className="mb-0">Order ID:</p>
                  <p className="mb-0">Order Date:</p>
                  <p className="mb-0">Delivery Date:</p>
                </div>
                <div className="col-6 text-right">
                  <p className="font-weight-bold text-capitalize mb-0">
                    {customer.customerName}
                  </p>
                  <p className="font-weight-bold mb-0">#{order.orderid}</p>
                  <p className="font-weight-bold mb-0">
                    {new Date(order?.orderdate).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "short",
                      day: "2-digit",
                    })}
                  </p>
                  <p className="font-weight-bold mb-0">
                    {new Date(order?.deliveryDate).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "short",
                      day: "2-digit",
                    })}
                  </p>
                </div>
              </div>
              <div className="row border-bottom pb-3 mb-3">
                <div className="col-6">
                  <p className="mb-0">Order Amount:</p>
                  <p className="mb-0">Paid Amount:</p>
                </div>
                <div className="col-6 text-right">
                  <p className="font-weight-bold mb-0">
                    {" "}
                    <i className="fas fa-rupee-sign"></i>
                    {order.netAmt}
                  </p>
                  <p className="font-weight-bold mb-0">
                    <i className="fas fa-rupee-sign"></i>
                    {order.paidAmt}
                  </p>
                </div>
              </div>
              <div className="row border-bottom pb-3 mb-3">
                <div className="col-6">
                  <p className="mb-0">Balance:</p>
                </div>
                <div className="col-6 text-right">
                  <p className="font-weight-bold mb-0">
                    <i className="fas fa-rupee-sign"></i>
                    {order.netAmt - order.paidAmt}
                  </p>
                </div>
              </div>
              <div className="row border-bottom pb-3 mb-3">
                <div className="col-6">
                  <div className="form-group">
                    <label htmlFor="">Paid Amount</label>
                    <input
                      type="number"
                      name="amount"
                      onChange={(e) => {
                        setBal(parseInt(e.target.value));
                      }}
                      value={bal}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label htmlFor="">Payment Type</label>
                    <select
                      name="method"
                      id="paymentType"
                      className="form-control"
                    >
                      <option disabled="disabled" selected="selected" value="">
                        Select The Payment Method
                      </option>
                      <option value="cash">Cash</option>
                      <option value="card">Card</option>
                      <option value="cheque">cheque</option>
                      <option value="bank_transfer">Bank Transfer</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer justify-content-end">
              <button
                type="button"
                className="btn btn-info text-right"
                onClick={addPayment}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`modal fade ${selectIron ? "show" : ""}`}
        style={{ display: selectIron ? "block" : "none" }}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="addCustomerModalLabel"
        aria-hidden={!selectIron}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addCustomerModalLabel">
                Select Iron Man
              </h5>
              <button
                type="button"
                className="close"
                onClick={() => {
                  setSelectIron(false);
                }}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form className="form-horizontal" id="addCustomer">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card card-info shadow">
                      <div className="card-body">
                        <div>
                          <select
                            name="status"
                            className="form-control change-status"
                            value={selectedIron.id}
                            onChange={changeIron}
                          >
                            <option value="">Select Iron Man</option>
                            {ironMan.map((item, index) => (
                              <option value={item.id}>
                                {item.ironmanName}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <button
                      type="button"
                      className="btn btn-info"
                      onClick={updateStatus}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Print order={order} />
  );
};
export default Orderview;
