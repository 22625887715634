import React, { useState, useEffect } from "react";
import axiosInstance from "../axiosConfig";
import { useLocation, useNavigate } from "react-router-dom";
import $ from "jquery";
import Swal from "sweetalert2";

const CreateService = () => {
  const [showModal, setShowModal] = useState(false);
  const [serviceName, setServiceName] = useState("");
  const [serviceImg, setServiceImg] = useState("");
  const [serviceTypeOption, setServiceTypeOption] = useState([]);
  const [serviceTypes, setServiceTypes] = useState([]);
  const [serviceTypesAll, setServiceTypesAll] = useState([]);

  const [errors, setErrors] = useState({});
  const [customers, setCustomers] = useState({});
  const location = useLocation();
  let id = location.state.data;
  const Navigate = useNavigate();

  // Function to add a new service type row
  const addServiceType = () => {
    setServiceTypes([...serviceTypes, { service: "", price: "" }]);
  };

  // Function to handle changes in service type and price
  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const updatedServiceTypes = serviceTypes.map((serviceType, i) =>
      i === index ? { ...serviceType, [name]: value } : serviceType
    );
    setServiceTypes(updatedServiceTypes);
  };

  // Function to delete a service type row
  const deleteServiceType = (index) => {
    const updatedServiceTypes = serviceTypes.filter((_, i) => i !== index);
    setServiceTypes(updatedServiceTypes);
  };

  const fetchCustomers = async () => {
    try {
      const response = await axiosInstance.get("/service?id=" + id);

      if (response?.status === "success") {
        setCustomers(response.data[0]);
        const data = response.data[0];
        setServiceName(data.serviceName);
        setServiceImg(data.serviceImg);

        const response2 = await axiosInstance.get(
          "/serviceprice?columns=id,service,serviceid,servicename,price&serviceid=" +
            id
        );
        $("#load").hide();

        if (response2?.status === "success") {
          setServiceTypes(response2.data);
          setServiceTypesAll(response2.data);
        }
      } else {
        console.error("Error fetching customers:", response);
        setCustomers({});
      }
    } catch (error) {
      $("#load").hide();

      setCustomers({});
      console.error("Error fetching customers:", error);
    }
  };

  const fetchServicetypes = async () => {
    try {
      const response = await axiosInstance.get(
        "/servicetype?columns=servicetype,id"
      );

      if (response?.status === "success") {
        setServiceTypeOption(response.data);
      } else {
        console.error("Error fetching customers:", response);
        setServiceTypeOption([]);
      }
    } catch (error) {
      setServiceTypeOption([]);
      console.error("Error fetching customers:", error);
    }
  };

  const validateForm = () => {
    const newErrors = {};
    // Required fields
    if (serviceName == "") newErrors.serviceName = "service type is required.";
    if (serviceImg == "") newErrors.serviceImg = "service Image is required.";

    if (serviceTypes.length === 0) {
      newErrors.serviceTypes = "service Type is required.";
    } else {
      const serviceNames = new Set(); // Initialize a Set to track unique service names

      const updatedServiceTypes = serviceTypes.map((item, index) => {
        if (item.price === "" && item.service === "") {
          if (item.price === "") {
            newErrors[`servicePrice${index}`] = "Service Price are required.";
          }
          if (item.service === "") {
            newErrors[`service${index}`] = "Service  are required.";
          }
          return item; // Return the item without changes
        } else {
          // If price is not a number, set to zero or handle accordingly
          item.price = item.price === "" ? 0 : parseInt(item.price);
          if (item.service !== "") {
            if (serviceNames.has(item.service)) {
              newErrors[
                `duplicateService${index}`
              ] = `Service "${item.service}" is already added.`;
            } else {
              serviceNames.add(item.service); // Add the service name to the Set
            }
          }
          item.service = item.service; // No changes needed, just for clarity
          return item; // Return the updated item
        }
      });

      setServiceTypes(updatedServiceTypes);
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Returns true if no errors
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      if (id == null) {
        const data = new FormData();
        data.append("serviceName", serviceName);
        data.append("serviceImg", serviceImg);

        try {
          // Call the API using POST method
          const response = await axiosInstance.post("/service", data);

          // Check the response from the API
          if (response?.status === "success") {
            var failed = false;
            serviceTypes.map(async (item, index) => {
              var servicePrice = item;
              servicePrice["serviceid"] = response?.data?.id;
              servicePrice["servicename"] = serviceName;
              const response1 = await axiosInstance.post(
                "/serviceprice",
                servicePrice
              );

              if (response1?.status !== "success") {
                failed = true;
              }

              if (index + 1 == serviceTypes.length) {
                Swal.fire({
                  toast: true,
                  position: "top-end",
                  icon: failed ? "error" : "success",
                  title: "Service " + response.message,
                  showConfirmButton: false,
                  timer: 1500,
                  timerProgressBar: true,
                  customClass: {
                    popup: "custom-toast",
                  },
                });
                Navigate("/services");
              }
            });
          } else {
            console.error("Error in response:", response);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      } else {
        const data = new FormData();
        data.append("serviceName", serviceName);
        data.append("serviceImg", serviceImg);

        try {
          // Call the API using POST method
          const response = await axiosInstance.put("/service?id=" + id, data);

          // Check the response from the API
          if (response?.status === "success") {
            var failed = false;

            serviceTypesAll.map(async (item, index) => {
              debugger;
              const deleted = serviceTypes.filter((item1) => {
                if (item.id == item1.id) return item;
              });

              if (deleted.length === 0) {
                var response1 = await axiosInstance.delete(
                  "/serviceprice?id=" + item.id
                );
                if (response1?.status !== "success") {
                  failed = true;
                }
              }
            });

            serviceTypes.map(async (item, index) => {
              var servicePrice = item;
              if (servicePrice.hasOwnProperty("serviceid")) {
                var response1 = await axiosInstance.put(
                  "/serviceprice?id=" + item.id,
                  servicePrice
                );
              } else {
                servicePrice["serviceid"] = id;
                servicePrice["servicename"] = serviceName;
                var response1 = await axiosInstance.post(
                  "/serviceprice",
                  servicePrice
                );
              }

              if (response1?.status !== "success") {
                failed = true;
              }

              if (index + 1 == serviceTypes.length) {
                Swal.fire({
                  toast: true,
                  position: "top-end",
                  icon: failed ? "error" : "success",
                  title: "Service " + response.message,
                  showConfirmButton: false,
                  timer: 1500,
                  timerProgressBar: true,
                  customClass: {
                    popup: "custom-toast",
                  },
                });
                Navigate("/services");
              }
            });
          } else {
            console.error("Error in response:", response);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    }
  };

  useEffect(() => {
    if (id != null) {
      fetchCustomers();
    }
    fetchServicetypes();
    $(document).on("click", ".icon-box", function () {
      var src = $(this).children("img").attr("src");
      var id = $(this).attr("data-id");
      $("#image").attr("src", src);
      $("input[name=img_name]").val(id);
      setServiceImg(id);
      setShowModal(false);
    });
  }, []); // Empty dependency array to run once on mount

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              <h1 className="m-0 mr-2 text-dark d-inline-block">Add Service</h1>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/dashboard">Dashboard</a>
                </li>
                <li className="breadcrumb-item">
                  <a href="/services">Services</a>
                </li>
                <li className="breadcrumb-item active">Add Service</li>
              </ol>
            </div>
            <div className="col-sm-6 text-right align-self-center"></div>
          </div>
        </div>
      </div>
      <section className="content card mx-3 shadow">
        <div className="container-fluid card-body">
          <form className="form-horizontal">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-info shadow">
                  <div className="card-header">
                    <h3 className="card-title">Service Details</h3>
                  </div>

                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6 col-12">
                        <div className="form-group">
                          <label>
                            Service Name<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="service"
                            value={serviceName}
                            onChange={(e) => {
                              setServiceName(e.target.value);
                            }}
                            placeholder="Enter Service Name"
                          />
                          {errors.serviceName && (
                            <small className="text-danger">
                              {errors.serviceName}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="col-md-3 col-12">
                        <div className="form-group">
                          <label className="d-block">
                            Service Icon<span className="text-danger">*</span>
                          </label>
                          <button
                            type="button"
                            className="btn btn-info showMedia"
                            onClick={() => {
                              setShowModal(true);
                            }}
                          >
                            Choose
                          </button>

                          {errors.serviceImg && (
                            <small className="text-danger">
                              {errors.serviceImg}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="col-md-3 text-right">
                        <img
                          id="image"
                          src={`${
                            serviceImg != ""
                              ? "../../images/" + serviceImg
                              : "../../images/default.jpg"
                          }`}
                          alt=""
                          width="80px"
                          height="80px"
                        />
                        <input type="text" name="img_name" hidden />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="card card-info shadow">
                  <div className="card-header">
                    <h3 className="card-title">Service Types</h3>
                  </div>
                  <div className="card-body">
                    <table className="table mb-3">
                      <thead>
                        <tr>
                          <th width="50%">Service Type</th>
                          <th>Service Price</th>
                          <th width="100px"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {serviceTypes.map((serviceType, index) => (
                          <tr key={index}>
                            <td>
                              <select
                                name="service"
                                value={serviceType.service}
                                onChange={(e) => handleInputChange(index, e)}
                                className="form-control"
                              >
                                <option value="" disabled>
                                  Select Service
                                </option>
                                {serviceTypeOption.map((item) => (
                                  <option
                                    value={item.servicetype}
                                    key={`option${item.id}`}
                                  >
                                    {item.servicetype}
                                  </option>
                                ))}
                                {/* Add more service options as needed */}
                              </select>
                              {errors[`service${index}`] && (
                                <small className="text-danger">
                                  {errors[`service${index}`]}
                                </small>
                              )}
                              {errors[`duplicateService${index}`] && (
                                <small className="text-danger">
                                  {errors[`duplicateService${index}`]}
                                </small>
                              )}
                            </td>
                            <td>
                              <input
                                type="number"
                                name="price"
                                value={serviceType.price}
                                onChange={(e) => handleInputChange(index, e)}
                                placeholder="Enter Price"
                                className="form-control"
                              />
                              {errors[`servicePrice${index}`] && (
                                <small className="text-danger">
                                  {errors[`servicePrice${index}`]}
                                </small>
                              )}
                            </td>
                            <td>
                              <button
                                type="button"
                                className="btn btn-sm btn-danger"
                                onClick={() => deleteServiceType(index)}
                              >
                                <i className="fa fa-trash"></i> Delete
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="row text-right">
                      <div className="col-md-12">
                        <button
                          type="button"
                          className="btn btn-sm btn-dark add-serviceType"
                          onClick={addServiceType}
                        >
                          <i className="fa fa-plus"></i> Add Service Type
                        </button>
                        {errors.serviceTypes && (
                          <small className="text-danger">
                            {errors.serviceTypes}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <button
                  type="submit"
                  className="btn btn-info"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>

      <div
        className={`modal fade ${showModal ? "show" : ""}`}
        style={{ display: showModal ? "block" : "none" }}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="modal-default"
        aria-hidden={!showModal}
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Select Icon</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" id="dress-icon">
              <div className="row">
                <div
                  className="col-md-3 icon-box mb-3 p-3 border"
                  data-id="blazer.png"
                >
                  <img src="../../images/blazer.png" className="w-100" />
                </div>
                <div
                  className="col-md-3 icon-box mb-3 p-3 border"
                  data-id="w-shirt.png"
                >
                  <img src="../../images/w-shirt.png" className="w-100" />
                </div>
                <div
                  className="col-md-3 icon-box mb-3 p-3 border"
                  data-id="jersey-blazer.png"
                >
                  <img src="../../images/jersey-blazer.png" className="w-100" />
                </div>
                <div
                  className="col-md-3 icon-box mb-3 p-3 border"
                  data-id="shirt.png"
                >
                  <img src="../../images/shirt.png" className="w-100" />
                </div>
                <div
                  className="col-md-3 icon-box mb-3 p-3 border"
                  data-id="sweater.png"
                >
                  <img src="../../images/sweater.png" className="w-100" />
                </div>
                <div
                  className="col-md-3 icon-box mb-3 p-3 border"
                  data-id="sweatshirt.png"
                >
                  <img src="../../images/sweatshirt.png" className="w-100" />
                </div>
                <div
                  className="col-md-3 icon-box mb-3 p-3 border"
                  data-id="tshirt.png"
                >
                  <img src="../../images/tshirt.png" className="w-100" />
                </div>
                <div
                  className="col-md-3 icon-box mb-3 p-3 border"
                  data-id="jeans.png"
                >
                  <img src="../../images/jeans.png" className="w-100" />
                </div>
                <div
                  className="col-md-3 icon-box mb-3 p-3 border"
                  data-id="trouser.png"
                >
                  <img src="../../images/trouser.png" className="w-100" />
                </div>
                <div
                  className="col-md-3 icon-box mb-3 p-3 border"
                  data-id="bed-sheets.png"
                >
                  <img src="../../images/bed-sheets.png" className="w-100" />
                </div>
                <div
                  className="col-md-3 icon-box mb-3 p-3 border"
                  data-id="pillow.png"
                >
                  <img src="../../images/pillow.png" className="w-100" />
                </div>
                <div
                  className="col-md-3 icon-box mb-3 p-3 border"
                  data-id="sneakers.png"
                >
                  <img src="../../images/sneakers.png" className="w-100" />
                </div>
                <div
                  className="col-md-3 icon-box mb-3 p-3 border"
                  data-id="shoes.png"
                >
                  <img src="../../images/shoes.png" className="w-100" />
                </div>
                <div
                  className="col-md-3 icon-box mb-3 p-3 border"
                  data-id="onesie.png"
                >
                  <img src="../../images/onesie.png" className="w-100" />
                </div>
                <div
                  className="col-md-3 icon-box mb-3 p-3 border"
                  data-id="kids-clothes.png"
                >
                  <img src="../../images/kids-clothes.png" className="w-100" />
                </div>
                <div
                  className="col-md-3 icon-box mb-3 p-3 border"
                  data-id="woman-clothes.png"
                >
                  <img src="../../images/woman-clothes.png" className="w-100" />
                </div>
                <div
                  className="col-md-3 icon-box mb-3 p-3 border"
                  data-id="socks.png"
                >
                  <img src="../../images/socks.png" className="w-100" />
                </div>
                <div
                  className="col-md-3 icon-box mb-3 p-3 border"
                  data-id="crop-top.png"
                >
                  <img src="../../images/crop-top.png" className="w-100" />
                </div>
                <div
                  className="col-md-3 icon-box mb-3 p-3 border"
                  data-id="blazer.png"
                >
                  <img src="../../images/blazer.png" className="w-100" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateService;
