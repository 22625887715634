const Print = ({ order }) => {
  return (
    <div className="content-wrapper receipt-content" id="receipt-content">
      <img src="../site-img/1261601831laundry.png" />
      <h3 className="center-text">Receipt</h3>

      <table className="info-table">
        <tbody>
          <tr>
            <th>Order ID:</th>
            <td>{order?.orderid}</td>
          </tr>
          <tr>
            <th>Customer Name:</th>
            <td>{order?.customername}</td>
          </tr>
          <tr>
            <th>Customer Phone:</th>
            <td>{order?.customerphone}</td>
          </tr>
          <tr>
            <th>Order Date:</th>

            <td>
              {new Date(order?.orderdate).toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
                day: "2-digit",
              })}
            </td>
          </tr>
          <tr>
            <th>Delivery Date:</th>
            <td>
              {new Date(order?.deliveryDate).toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
                day: "2-digit",
              })}
            </td>
          </tr>
        </tbody>
      </table>

      <div className="services">
        <h4 className="center-text">Items</h4>
        <table className="items-table">
          <thead>
            <tr>
              <th>Item</th>
              <th>Qty</th>
              <th>Price</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {order?.services?.map((service, index) => (
              <tr key={index}>
                <td>
                  {service.serviceName}
                  <br />

                  <small>{`[${service.servicetypeName}]`}</small>
                </td>
                <td>{service.qty}</td>
                <td>${service.price?.toFixed(2)}</td>
                <td>${(service.qty * service.price)?.toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <table className="summary-table">
        <tbody>
          <tr>
            <th>Total Amount:</th>
            <td>${order?.totalAmt?.toFixed(2)}</td>
          </tr>
          <tr>
            <th>Discount:</th>
            <td>${order?.discount?.toFixed(2)}</td>
          </tr>
          {/* <tr>
            <th>Payment Type:</th>
            <td>{order?.paymentType || "-"}</td>
          </tr> */}
          <tr>
            <th>Net Amount:</th>
            <td>${order?.netAmt?.toFixed(2)}</td>
          </tr>
          <tr>
            <th>Paid Amount:</th>
            <td>${order?.paidAmt?.toFixed(2)}</td>
          </tr>
          <tr>
            <th>Balance:</th>
            <td>
              $
              {(order?.totalAmt - (order?.paidAmt + order?.discount))?.toFixed(
                2
              )}
            </td>
          </tr>
        </tbody>
      </table>

      <p className="center-text footer-text">Thank you for your business!</p>
    </div>
  );
};
export default Print;
